import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { UnsubscribeOnDestroyAdapter} from '../../shared/UnsubscribeOnDestroyAdapter';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService extends UnsubscribeOnDestroyAdapter {

  private readonly API_URL = environment.apiUrl + 'api/login/';
  private readonly API_URL_2 = environment.apiUrl + 'api/register/';
  private readonly API_URL_3 = environment.apiUrl + 'api/userAlumno/';
  private readonly API_URL_4 = environment.apiUrl + 'api/userDocente/';


  dataChange: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {
    super();
  }
  get data(): User[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */

  loginUser(user: any) {
    return this.httpClient.post<any>(this.API_URL, user);
  }
  
  /* getAllStaffs(): void {
    this.subs.sink = this.httpClient.get<User[]>(this.API_URL).subscribe(
      (data) => {

      },
      (error: HttpErrorResponse) => {

        console.log(error.name + ' ' + error.message);
      }
    );
  } */

  getUserByAlumno(student: any) {
    return this.httpClient.get<any>(this.API_URL_3 + student.id_alumno);
  }

  getUserByDocente(docente: any) {
    return this.httpClient.get<any>(this.API_URL_4 + docente.id);
  }

  addUser(user: User): void {
    this.dialogData = user;

      this.httpClient.post(this.API_URL_2, user).subscribe(data => {
      this.dialogData = user;
      },
      (err: HttpErrorResponse) => {
     // error code here
    });
  }

  updateUserAlumno(user: User): void {
    this.dialogData = user;

      this.httpClient.patch(this.API_URL_3 + user.id, user).subscribe(data => {
      this.dialogData = user;
      },
      (err: HttpErrorResponse) => {
     // error code here
    });
  }

  updateUserDocente(user: User): void {
    this.dialogData = user;

      this.httpClient.patch(this.API_URL_4 + user.id, user).subscribe(data => {
      this.dialogData = user;
      },
      (err: HttpErrorResponse) => {
     // error code here
    });
  }
  
}
