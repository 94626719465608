import { OnInit } from '@angular/core';
import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules

  {
    path: '',
    title: 'MENUITEMS.DASHBOARD.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/dashboard/main',
        title: 'MENUITEMS.DASHBOARD.LIST.DASHBOARD1',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.TEACHERS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'person',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/teachers/all-teachers',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.TEACHERS.LIST.ALL-TEACHERS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/teachers/add-teacher',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.TEACHERS.LIST.ADD-TEACHER',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },

    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STAFF.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'place',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/salas/all-salas',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.STAFF.LIST.ALL-STAFF',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/salas/add-salas',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.STAFF.LIST.ADD-STAFF',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.COURSES.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'school',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/courses/all-courses',
       //path: '/admin/dashboard/main',
        title: 'MENUITEMS.COURSES.LIST.ALL-COURSES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/courses/add-course',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.COURSES.LIST.ADD-COURSE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
     
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENTS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/students/add-student',
        title: 'MENUITEMS.STUDENTS.LIST.ADD-STUDENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },

      {
        path: '/admin/students/all-students',
        title: 'MENUITEMS.STUDENTS.LIST.ALL-STUDENTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    
      {
        path: '/admin/students/admitidos-student',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-ATTENDANCE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },

      {
        path: '/admin/students/taller-lista',
        title: 'MENUITEMS.STUDENTS.LIST.TALLER-LISTA',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },

   {
    path: '/admin/settings',
    title: 'MENUITEMS.STUDENT.LIST.SETTINGS',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  }, 

 






  // Kardex Modules
  /* {
    path: '',
    title: 'MENUITEMS.STUDENTS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Kardex'],
    submenu: [
      {
        path: '/kardex/students/add-student',
        title: 'MENUITEMS.STUDENTS.LIST.ADD-STUDENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },

      {
        path: '/kardex/students/all-students',
        title: 'MENUITEMS.STUDENTS.LIST.ALL-STUDENTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
     
      {
        path: '/kardex/students/student-attendance',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-ATTENDANCE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  }, */


  // Teacher Modules

  {
    path: '/teacher/dashboard',
    title: 'MENUITEMS.TEACHER.LIST.DASHBOARD',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/asignaturas',
    title: 'MENUITEMS.TEACHER.LIST.EXAM-SCHEDULE',
    iconType: 'material-icons-two-tone',
    icon: 'history_edu',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/alumnos',
    title: 'MENUITEMS.TEACHER.LIST.LECTURES',
    iconType: 'material-icons-two-tone',
    icon: 'menu_book',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  
  // Student Modules

  {
    path: '/student/dashboard',
    title: 'MENUITEMS.STUDENT.LIST.DASHBOARD',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/asignaturas',
    title: 'MENUITEMS.STUDENT.LIST.LEAVE-REQUEST',
    iconType: 'material-icons-two-tone',
    icon: 'offline_pin',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
 
  {
    path: '/student/calificaciones',
    title: 'MENUITEMS.STUDENT.LIST.HOMEWORK',
    iconType: 'material-icons-two-tone',
    icon: 'article',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
 
  // Common Module

  {
    path: '',
    title: 'MENUITEMS.APPS.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  /* {
    path: 'calendar',
    title: 'MENUITEMS.CALENDAR.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: '',
    groupTitle: false,
    badge: 'New',
    badgeClass: 'badge bg-blue sidebar-badge float-end',
    role: ['All'],
    submenu: [],
  }, */
 
 
];










export const ROUTES_KARDEX: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules

  {
    path: '',
    title: 'MENUITEMS.DASHBOARD.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/dashboard/main',
        title: 'MENUITEMS.DASHBOARD.LIST.DASHBOARD1',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.STUDENTS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/students/add-student',
        title: 'MENUITEMS.STUDENTS.LIST.ADD-STUDENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },

      {
        path: '/admin/students/all-students',
        title: 'MENUITEMS.STUDENTS.LIST.ALL-STUDENTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    
      {
        path: '/admin/students/admitidos-student',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-ATTENDANCE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/students/taller-lista',
        title: 'MENUITEMS.STUDENTS.LIST.TALLER-LISTA',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },

   

 
];





export const ROUTES_JEFATURA: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules

  {
    path: '',
    title: 'MENUITEMS.DASHBOARD.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/dashboard/main',
        title: 'MENUITEMS.DASHBOARD.LIST.DASHBOARD1',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.TEACHERS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'person',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/teachers/all-teachers',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.TEACHERS.LIST.ALL-TEACHERS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/teachers/add-teacher',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.TEACHERS.LIST.ADD-TEACHER',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },

    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STAFF.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'classroom',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/salas/all-salas',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.STAFF.LIST.ALL-STAFF',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/salas/add-salas',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.STAFF.LIST.ADD-STAFF',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.COURSES.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'school',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/courses/all-courses',
       //path: '/admin/dashboard/main',
        title: 'MENUITEMS.COURSES.LIST.ALL-COURSES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/courses/add-course',
        //path: '/admin/dashboard/main',
        title: 'MENUITEMS.COURSES.LIST.ADD-COURSE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
     
    ],
  },
  

];